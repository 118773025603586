import React from "react"

const CallToAction = ({ name }) => {
  return (
    <>
      <button className="call-to-action">{name}</button>
    </>
  )
}

export default CallToAction


