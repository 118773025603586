import React from "react"
import { BsLinkedin } from "react-icons/bs"
import { BsGithub } from "react-icons/bs"

const Footer = () => {
  return (
    <>
      <div className="footer-line"></div>
      <footer>
        <a href="mailto:alexandrumaluselu@gmail.com" class="mail" id="mail">
          alexandrumaluselu@gmail.com
        </a>
        <div class="fo-icons">
          <a
            target="_blank"
            href="https://www.linkedin.com/in/alexandru-maluselu/"
          >
            <BsLinkedin className="footer-icon" />
          </a>
          <a target="_blank" href="https://github.com/AlexAxi">
            <BsGithub className="footer-icon fo-icon-adj" />
          </a>
        </div>
      </footer>
    </>
  )
}

export default Footer
