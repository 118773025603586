import React from "react"
import { useState, useEffect } from "react"
import { motion } from "framer-motion"
import { GiTeamIdea } from "react-icons/gi"
import { GiBookmarklet } from "react-icons/gi"
import { TbDrone } from "react-icons/tb"
import { IoRocket } from "react-icons/io5"
import { BsPersonCircle } from "react-icons/bs"

const StackCards = () => {
  const [active1, setActive1] = useState(false)
  const [active2, setActive2] = useState(false)
  const [active3, setActive3] = useState(false)
  const [active4, setActive4] = useState(false)
  const [active5, setActive5] = useState(false)

  useEffect(() => {
    setActive1(true)
  }, active1)
  return (
    <div>
      <div className="options">
        <motion.div
          className={active1 ? "option active" : "option"}
          initial={{opacity: .5 }}
          whileInView={{
            opacity: 1 ,
           
          }}
          transition={{ duration: 0.5 }}
        >
          <div className="shadow">
            <motion.div className="option-content-wrap">
              <ul className={!active1 ? "inactive" : "active-option-content"}>
                <li>
                  A far-sighted planner, logical thinker with a keen sense of
                  priorities.
                </li>
                <li>
                  I evaluate people on their results and I am diplomatic when
                  the situation calls for it.
                </li>
                <li>
                  An ingenious thinker with great ability in long-range
                  planning.
                </li>
                <li>Curious about processes with a strong task focus.</li>
                <li>I know the importance of detail within the process.</li>
              </ul>
            </motion.div>
          </div>

          <div className="label"
            onClick={() => {
              setActive1(!active1)
              setActive2(false)
              setActive3(false)
              setActive4(false)
              setActive5(false)
            }}>
            <div
              className="icon"
              
            >
              <i>
                <IoRocket className="icon-img " style={{ marginTop: ".2em" }} />
              </i>
            </div>
            <div className="info">
              <div className="main">Key strengths</div>
              {/* <div className="sub">How I bring value</div> */}
            </div>
          </div>
        </motion.div>
        <div className={active2 ? "option active" : "option"}>
          <div className="shadow">
            <div className="option-content-wrap">
              <ul className={!active2 ? "inactive" : "active-option-content"}>
                <li>
                  I encourage down-to-earth realism. Always make sure that the
                  team uses the correct methods.
                </li>
                <li>
                  I can quickly determine the important factors when problems
                  arise.
                </li>
                <li>
                  I commit to realistic goals and assume responsibility and can
                  be relied upon to pull more than my weight.
                </li>
                <li>
                  I challenge existing methods if I feel they are inadequate.
                  Will drive others to achieve greater things.
                </li>
                <li>
                  I defend the truth and I am not frightened to fight for it.
                </li>
              </ul>
            </div>
          </div>

          <div className="label"
          onClick={() => {
            setActive2(!active2)
            setActive1(false)
            setActive3(false)
            setActive4(false)
            setActive5(false)
          }}>
            <div
              className="icon"
              
            >
              <i>
                <GiTeamIdea className="icon-img " />
              </i>
            </div>
            <div className="info">
              <div className="main">A team player</div>
              {/* <div className="sub">How I work with others </div> */}
            </div>
          </div>
        </div>
        <div className={active3 ? "option active" : "option"}>
          <div className="shadow">
            <div className="option-content-wrap">
              <ul className={!active3 ? "inactive" : "active-option-content"}>
                <li>
                  An optimistic and positive person living mainly in the here
                  and now
                </li>
                <li>Adaptable realist, relying on facts</li>
                <li>
                  Hardworking, righteous, fiercely independent, and convinced
                  that moral good must win above all else
                </li>
                <li>
                  I can spot the flaws that may exist in most situations and
                  quickly see how to improve them
                </li>
                <li>
                  As a natural manager, I find myself in command through my
                  ability to plan and keep both long and short-term objectives
                  clearly in mind
                </li>
              </ul>
            </div>
          </div>

          <div className="label"
            onClick={() => {
              setActive3(!active3)
              setActive1(false)
              setActive2(false)
              setActive4(false)
              setActive5(false)
            }}>
            <div
              className="icon"
            
            >
              <i>
                <BsPersonCircle
                  className="icon-img "
                  style={{ marginTop: ".3em" }}
                />
              </i>
            </div>
            <div className="info">
              <div className="main">Personality</div>
              {/* <div className="sub">My way of being</div> */}
            </div>
          </div>
        </div>
        <div className={active4 ? "option active" : "option"}>
          <div className="shadow">
            <div className="option-content-wrap">
              <ul className={!active3 ? "inactive" : "active-option-content"}>
                <li>
                 I created my first website in 2019, my own WordPress blog about gadgets
                </li>
                <li>
                  Learned design principles, content creation and online marketing
                </li>
                <li>
                  I started creating websites as a freelancer for local businesses 
                </li>
                <li>Got a degree in Multimedia Design</li>
                <li>I have been an intern UX/UI Designer and Frontend Developer</li>
              </ul>
            </div>
          </div>
          <div className="label"
           onClick={() => {
            setActive4(!active4)
            setActive1(false)
            setActive2(false)
            setActive3(false)
            setActive5(false)
          }}>
            <div
              className="icon"
             
            >
              <i>
                <GiBookmarklet
                  className="icon-img "
                  style={{ marginTop: ".5em" }}
                />
              </i>
            </div>
            <div className="info">
              <div className="main">My story</div>
              {/* <div className="sub">How I become a designer</div> */}
            </div>
          </div>
        </div>
        <div className={active5 ? "option active" : "option"}>
          <div className="shadow">
            <div className="option-content-wrap">
              <ul className={!active3 ? "inactive" : "active-option-content"}>
                <li>
                 Drone Videography, Photography <br /> <a className="youtube-link" href="https://www.youtube.com/channel/UCVidA0e2SnwJJ-sHm_fxerQ" target="_blank">See videos</a>
                </li>
                <li>
                DIY Projects
                </li>
                <li>
                 Guitar Playing
                </li>
                <li>Cycling</li>
                <li>Working out</li>
              </ul>
            </div>
          </div>
          <div className="label"
          onClick={() => {
            setActive5(!active5)
            setActive1(false)
            setActive2(false)
            setActive3(false)
            setActive4(false)
          }}>
            <div
              className="icon"
              
            >
              <i>
                <TbDrone className="icon-img " style={{ marginTop: ".2em" }} />
              </i>
            </div>
            <div className="info">
              <div className="main">Hobbies</div>
              {/* <div className="sub">In my free time</div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default StackCards
