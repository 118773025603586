import React from "react"

export default function PageTitles({ h2, h1 }) {
  return (
    <>
      <h2>{h2}</h2>
      <h1>{h1}</h1>
    </>
  )
}
