import React from "react"
import styled from "styled-components"
import PageTitles from "../PageTitles"
import { StaticImage } from "gatsby-plugin-image"
import CardStack from "./StackCards"

const AboutMe = () => {
  return (
    <Wrapper>
      <div className="skills-wrapper">
        <PageTitles h2="ABOUT ME" h1="Looking for success" />
        <div className="about-grid">
          <div
           
            className="about-img"
          
          />
          <CardStack />
        </div>
      </div>
    </Wrapper>
  )
}

export default AboutMe

const Wrapper = styled.section`
  background-color: #000000;
`
