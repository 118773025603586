import React from "react"
import { motion, AnimatePresence } from "framer-motion"
import { useState, useEffect } from "react"

import { FiX } from "react-icons/fi"
import { BsGithub } from "react-icons/bs"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import { Navigation, Pagination, Scrollbar, A11y } from "swiper"

import { Swiper, SwiperSlide } from "swiper/react"
import { SwiperCore, Autoplay } from "swiper"

// Import Swiper styles
import "swiper/css"
import "swiper/css/navigation"
import "swiper/css/pagination"
import "swiper/css/scrollbar"
import "swiper/css/autoplay"
import CallToAction from "../CallToAction"

const ProjectCards = ({ projects = [] }) => {
  const [selectedId, setSelectedId] = useState(null)
  const [title, setTitle] = useState(null)
  const [image, setImage] = useState(null)
  const [overview, setIOverview] = useState(null)
  const [steps, setSteps] = useState(null)
  const [video, setVideo] = useState(null)
  const [liveSite, setLiveSite] = useState(null)
  const [gitHubLink, setGitHubLink] = useState(null)

  const [lock, setLock] = useState(false)

  const [visible, setVisible] = useState(4)

  useEffect(() => {
    let body = document.getElementsByTagName("body")[0]

    // if (!isOpen) body.style.overflow = "hidden";
    // else body.style.overflow = "auto";

    {
      lock ? (body.style.overflow = "hidden") : (body.style.overflow = "auto")
    }
  }, [lock])

  ///Show or hide the number of projects displayed
  const showMoreProjects = () => {
    setVisible(prevValue => prevValue + 2)
  }

  const showLessProjects = () => {
    setVisible(prevValue => prevValue - 2)
  }
  console.log(projects)

  return (
    <div>
      <h2>PROJECTS</h2>
      <h1>What I've been working on</h1>
      <div className="cards-wrapper">
        {projects.slice(0, visible).map(project => {
          const {
            id,
            title,
            heroImage,
            video,
            overview,
            steps,
            liveWebsite,
            gitHub,
          } = project
          const pathToImage = getImage(heroImage)

          const herImgUrl = heroImage.gatsbyImageData.images.fallback.src
          // const slug = slugify(title, { lower: true })
          console.log("MORE", projects)
          return (
            <AnimatePresence>
              {visible > 0 ? (
                <motion.div
                  key={"projectC"}
                  className="card"
                  layoutId={id}
                  style={{ backgroundImage: `url(${herImgUrl})` }}
                  whileHover={{ scale: 1.04 }}
                  initial={{ opacity: 0.5 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0, transition: { duration: 0.5 } }}
                  transition={{ duration: 0.5 }}
                  onClick={() =>
                    setSelectedId(id) &
                    setTitle(title) &
                    setImage(pathToImage) &
                    setIOverview(overview) &
                    setSteps(steps) &
                    setVideo(video) &
                    setLiveSite(liveWebsite) &
                    setGitHubLink(gitHub) &
                    setLock(true)
                  }
                >
                  {/* <motion.h2 layout="position">{title}</motion.h2> */}

                  {/* <GatsbyImage
                image={pathToImage}
                alt={title}
                className="card-img"
                
              /> */}
                </motion.div>
              ) : null}
            </AnimatePresence>
          )
        })}
      </div>
      {visible < projects.length ? (
        <div className="load-more-btn" onClick={showMoreProjects}>
          <CallToAction onClick={showMoreProjects} name="SHOW MORE" />
        </div>
      ) : (
        <div className="load-more-btn" onClick={showLessProjects}>
          <CallToAction onClick={showLessProjects} name="SHOW LESS" />
        </div>
      )}

      {/* EXPANSION CARD */}
      <AnimatePresence>
        {selectedId && (
          <motion.div
            layout
            layoutId={selectedId}
            className="expand"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <motion.div className="expand-wrapper">
              <motion.button
                className="card-button"
                onClick={() => setSelectedId(null) & setLock(false)}
              >
                {/* <FiX className={"close-icon"} /> */}
                <svg
                 className={"close-icon"} 
                  viewBox="0 0 16 16"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  class="bi bi-x-lg"
                >
                  <path d="M1.293 1.293a1 1 0 0 1 1.414 0L8 6.586l5.293-5.293a1 1 0 1 1 1.414 1.414L9.414 8l5.293 5.293a1 1 0 0 1-1.414 1.414L8 9.414l-5.293 5.293a1 1 0 0 1-1.414-1.414L6.586 8 1.293 2.707a1 1 0 0 1 0-1.414z" />
                </svg>
              </motion.button>
              {/* <motion.h2 layout="position">{title}</motion.h2> */}
              {/* <GatsbyImage
              image={image}
              alt={title}
              className="about-img"
            /> */}
              {video ? (
                <video
                  controls
                  style={{ width: "100%", height: "auto" }}
                  playsInline
                  loop
                  muted
                  autoPlay
                  disablePictureInPicture
                  x-webkit-airplay="deny"
                  controlsList="nodownload noplaybackrate nodownload"
                  poster
                >
                  <source src={video.url} type="video/mp4" />
                </video>
              ) : null}
              <div className="expand-content-wrap">
                <div className="card-info-wrapper">
                  {/* LEFT */}
                  <div className="card-info-top-left">
                    <h2 layout="position" className="h2-project">
                      {title}
                    </h2>
                    {overview.overview.map((item, index) => {
                      return (
                        <p key={index} className="single-tool">
                          {item}
                        </p>
                      )
                    })}
                    <h2 layout="position" className="h2-project">
                      Goal
                    </h2>
                    {overview.goal.map((item, index) => {
                      return (
                        <p key={index} className="single-tool">
                          {item}
                        </p>
                      )
                    })}
                  </div>
                  <div className="vertical-line"></div>
                  {/* RIGHT */}
                  <div className="card-info-top-right">
                    <div className="grid-top-right">
                      <div>
                        {/* TOOLS */}

                        <div>
                          {overview.tools ? (
                            <div>
                              <h2 layout="position" className="h2-project">
                                Tools
                              </h2>
                              {overview.tools.map((item, index) => {
                                return (
                                  <motion.p key={index} className="single-tool">
                                    {item}
                                  </motion.p>
                                )
                              })}
                            </div>
                          ) : null}

                          {/* LINKS */}
                          <div className="card-links card-links-margin">
                            {liveSite ? (
                              <a
                                href={liveSite}
                                target="blank"
                                style={{ textDecoration: "none" }}
                              >
                                <button className="live-btn">VIEW LIVE</button>
                              </a>
                            ) : null}

                            {gitHubLink ? (
                              <a
                                className="git-btn"
                                href={gitHubLink}
                                target="blank"
                                style={{ textDecoration: "none" }}
                              >
                                <BsGithub />
                              </a>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <motion.h2 layout="position" className="h2-project">
                  Design Process
                </motion.h2>

                <Swiper
                  className="swiper-steps"
                  // install Swiper modules
                  modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
                  loop={true}
                  spaceBetween={0}
                   slidesPerView={1}
                  navigation
                  // autoplay={{ delay: 2000 }}
                  pagination={{ clickable: true }}
                  breakpoints={{
                  //  768: {
                   //   slidesPerView: "auto",
                 //   },
                  //  1280: {
                  //    slidesPerView: 1,
               //     },
                  }}
                  effect="fade"
                  //   scrollbar={{ draggable: true }}
                  onSwiper={swiper => console.log(swiper)}
                  // onSlideChange={() => console.log("slide change")}
                >
                  {steps.map((item, index) => {
                    const pathToSlide = getImage(item)
                    return (
                      <SwiperSlide>
                        {/* <motion.img
                          className="img-swiper"
                          src={item.file.url}
                          key={index}
                          // style={{
                          //   marginTop: ".5em",
                          //   height: "500px",
                          //   width: "100%",
                          //   objectFit: "cover",
                          // }}
                        ></motion.img> */}

                        <GatsbyImage
                          image={pathToSlide}
                          alt={title}
                          key={index}
                          objectPosition="top left"
                          objectFit="cover"
                          className="img-swiper"
                        />
                        <div style={{ marginTop: "3em" }}>
                          <motion.h2 key={index} className="h2-swiper ">
                            {item.title}
                          </motion.h2>
                          <motion.p key={index} className="single-tool">
                            {item.description}
                          </motion.p>
                        </div>
                      </SwiperSlide>
                    )
                  })}
                </Swiper>
              </div>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  )
}

export default ProjectCards
