import React from "react"
import { useRef, useEffect } from "react"

import AllProjects from "../components/Elements/Projects/AllProjects"

import Hero from "../components/Hero"
import Layout from "../components/Layout"
import Skills from "../components/Elements/Skills/Skills"
import AboutMe from "../components/Elements/About/AboutMe"
import ConclusionAction from "../components/ConclusionAction"
import SEO from "../components/SEO"



//  const executeScroll = () => myRef.current.scrollIntoView()

export default function Home() {
  const home = useRef()
  const projects = useRef()
  const about = useRef()
  const skills = useRef()
  useEffect(() => {
    if (window.location.href.indexOf("#projects") !== -1) {
      projects.current?.scrollIntoView({ behavior: "smooth" })
    } else if (window.location.href.indexOf("#about") !== -1) {
      about.current?.scrollIntoView({ behavior: "smooth" })
    } else if (window.location.href.indexOf("#skills") !== -1) {
      skills.current?.scrollIntoView({ behavior: "smooth" })
    } else if (window.location.href.indexOf("")) {
      home.current?.scrollIntoView({ behavior: "smooth" })
    }
  })

  return (
    <>
      {/* <div className="overlayEye"></div> */}
      <div ref={home}></div>
      <Layout id="layout">
        <SEO
          title="Alex.Ms.Design"
          description="Front-End Developer & UX/UI Designer using modern design techniques to create great user experiences.
          Always ready to adapt to create the best front-end solution for your company."
        />
        <Hero />
        <div ref={skills}></div>
        <Skills />
        <div ref={projects}></div>
        <AllProjects />
        <div ref={about}></div>
        <AboutMe />

        <ConclusionAction />
      </Layout>
    </>
  )
}
