import React from "react"
import styled from "styled-components"
import { StaticImage } from "gatsby-plugin-image"
import FlashCards from "./FlashCards"

const Skills = () => {
  return (
    <Wrapper>
      <section className="skills-wrapper">
        <h2>SKILLS</h2>
        <h1>The perfect skills mix</h1>
        <FlashCards />
        <section className="boxes-wrapper">
          <article className="skills-box">
            <h2 className="h2-skills" style={{ color: "white" }}>
              Design Techniques
            </h2>
            <div className="skills-grid">
              <ul className="skills-list">
                <li>Agile Development</li>
                <li>Design Thinking</li>
                <li>Scrum, Jira</li>
                <li>User Research</li>
                <li>Benchmarking</li>
                <li>I. Architecture</li>
                <li>User Flows</li>
                <li>Journey Maps</li>
              </ul>
              <ul className="skills-list">
                <li>Sitemaps</li>
                <li>Wire-frames</li>
                <li>HF Mockups</li>
                <li>Design Guides</li>
                <li>UX Lows</li>
                <li>Design Surveys</li>
                <li>Think Aloud Tests</li>
                <li>Web Copywriting</li>
              </ul>
            </div>
          </article>
          <article className="skills-box">
            <h2 className="h2-skills" style={{ color: "white" }}>
              Implementation
            </h2>
            <div className="skills-grid">
              <ul className="skills-list">
                <li>HTML, CSS, SASS</li>
                <li>Material UI</li>
                <li>JavaScript, TypeScript</li>
                <li>ReactJS, Redux</li>
                <li>Node.js</li>
                <li>Gatsby</li>
                <li>GraphQL</li>
              </ul>
              <ul className="skills-list">
                <li>Restful API</li>
                <li>MySQL</li>
                <li>MongoDB</li>
                <li>Netlify CD</li>
                <li>WordPress, PHP</li>
                <li>Contentful CMS</li>
                <li>GIT</li>
                <li>SEO</li>
              </ul>
            </div>
          </article>
        </section>
      </section>
    </Wrapper>
  )
}

export default Skills

const Wrapper = styled.section`
  background-color: #000000;
`
