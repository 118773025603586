import React from "react"
import Navbar from "./Navbar"
import Footer from "./Footer"
import styled from "styled-components"

import "normalize.css"
import '../assets/css/main.css'

const Layout = ({ children }) => {
  console.log(children)
  return (
    <Wrapper>
      <Navbar />
      {children}
      <Footer />
    </Wrapper>
  )
}

export default Layout

const Wrapper = styled.section`
background-color: #1E1E21;

.item{
    margin-right: 1em;
}
.gallery-img{
border-radius: 1rem;
}
`