import React, { useRef, useState,  useCallback } from "react"
import emailjs from "@emailjs/browser"
import { motion } from "framer-motion"
import { FiX } from "react-icons/fi"
import { BsLinkedin } from "react-icons/bs"
import { BsGithub } from "react-icons/bs"
import { MdOutlineEmail } from "react-icons/md"
import { IoMdCheckmarkCircleOutline } from "react-icons/io"

export const ContactForm = ({ onClose }) => {
  const form = useRef()
  const [submitted, setSubmitted] = useState(false)

  const handleClose = useCallback(
    event => {
      onClose(event.target.value)
    },
    [onClose]
  )

  //  function handleAutoClose (){
  //     if(submitted===true){
  //         setTimeout(handleClose(), 1700);
  //     }
  //   }

  const sendEmail = e => {
    e.preventDefault()

    emailjs
    .sendForm('service_0xv5byi', 'template_iuwu8mj', form.current, '4vJOwVKBc2I-Ss39j')
      // .sendForm(
      //   `${process.env.SPACE_ID}`,
      //   `${process.env.TEMPLATE_ID}`,
      //   form.current,
      //   `${process.env.PUBLIC_KEY}`
      // )
      .then(
        result => {
          document.getElementById("myForm").reset()
          setSubmitted(true)
          setTimeout(() => {
            setSubmitted(false)
          }, 1700)
          // handleAutoClose();

          console.log(result.text)
        },
        error => {
          console.log(error.text)
        }
      )
    // setSubmitted(true)
    // if (submitted) {
    //   console.log("SUBMITTED")
    //   document.getElementById("myForm").reset()
    //   //    setSubmittedPop(true)
    //   //    setTimeout(() => {setSubmittedPop(false)}, 1700);
    // } else {
    // }
  }

  return (
    <div className="form-overlap">
      <div className="form-wrapper">
        <div className="close-form">
          <FiX
            className={"close-form-icon"}
            style={{ color: "white" }}
            onClick={handleClose}
          />
        </div>
        <h2 className="h2-form">Write me a message</h2>

        <form
          ref={form}
          onSubmit={sendEmail}
          id="myForm"
          className="form-container"
        >
          <label>Name</label>
          <input
            type="text"
            name="user_name"
            placeholder="your name"
            required
          />
          <label>Email</label>
          <input
            type="email"
            name="user_email"
            placeholder="your@email.com"
            required
          />
          <label>Message</label>
          <textarea name="message" required placeholder="message" />
          <input type="submit" value="Send" className="submit-btn" />
        </form>
        <div className="form-container-details">
          <a
            href="mailto:alexandrumaluselu@gmail.com"
            className="a-form-top "
            id="mail"
          >
            alexandrumaluselu@gmail.com
          </a>
          <div className="form-icons">
            <a href="mailto:alexandrumaluselu@gmail.com" id="mail">
              <MdOutlineEmail className="mail-form" />
            </a>

            <a
              target="_blank"
              href="https://www.linkedin.com/in/alexandru-maluselu/"
            >
              <BsLinkedin className="form-icon " />
            </a>
            <a target="_blank" href="https://github.com/AlexAxi">
              <BsGithub className="form-icon  " />
            </a>
          </div>
        </div>
      </div>
      {submitted && (
        <div className="confirmation-form-wrap">
        <motion.section
          className="confirmation-form"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ transition: { duration: 0.2 } }}
          transition={{ duration: 0.1 }}
        >
          <h2 style={{ color: "white" }}>Email sent!</h2>
          <div className="message-confirm">
            <p>
              I will reach back to you soon <br /> <br /> Best regards! <br />
              Alex 
            </p>
            <IoMdCheckmarkCircleOutline className="submitted-icon" />
          </div>
        </motion.section>
        </div>
      )}
    </div>
  )
}
