import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import { motion, AnimatePresence } from "framer-motion"

import { FiAlignJustify } from "react-icons/fi"
import { FiX } from "react-icons/fi"
import { ContactForm } from "./ContactForm"

const Navbar = () => {
  const [show, setShow] = useState(false)
  

  const [move, setMove] = useState(false)
  const [scale, setScale] = useState(false)

  const [activeForm, setActiveForm] = useState(false)



  return (
    <>
      <nav className="navbar">
        <div className="nav-center">
          <div>
            <Link to="/" className="logo" >
              Alex.Ms.Design
            </Link>
          </div>

          <div className="nav-links">
            <Link to="#skills" className="nav-link">
              Skills
            </Link>
            <Link to="#projects" className="nav-link">
              Projects
            </Link>
            

            <Link to="#about" className="nav-link">
              About
            </Link>
            <button className="nav-link" onClick={() => setActiveForm(true)} style={{marginLeft:"1em", color:"#3997F7", fontWeight:600}}>
              Contact
            </button>
          </div>
        </div>
      </nav>

      {/* // MOBILE NAV */}
      <nav className="nav-mobile" >
        <div className="nav-center-mobile">
          <Link to="/" className="logo-mobile" onClick={() => setShow(false)}>
            Alex.Ms.Design
          </Link>

          <motion.button
            className="nav-btn nav-btn-mobile"
            animate={{ scale: scale ? 0.9 : 0.8 }}
            onClick={() => setShow(!show) & setMove(!move) & setScale(!scale)}
          >
            {!show ? (
              <FiAlignJustify className={"burger"} />
            ) : (
              <FiX className={"burger"} />
            )}
          </motion.button>
        </div>
        {/* menu */}
        <AnimatePresence>
          {move && (
            <motion.div
              key="dropdown"
              className="nav-links-mobile"
              initial={{ opacity: 0, visibility: "hidden" }}
              animate={{ opacity: 1, visibility: "visible" }}
              exit={{
                opacity: 0,
                visibility: "hidden",
                transition: { duration: 1, ease: "easeOut" },
              }}
              // animate={{ x: !move ? 200 : 0 }}
              transition={{ duration: 0.4, ease: "easeOut" }}
            >
              <Link
                to="#skills"
                className="nav-link nav-link-mobile "
                onClick={() =>
                  setShow(false) & setMove(false) & setScale(false)
                }
              >
                Skills
              </Link>

              <Link
                to="#projects"
                className="nav-link nav-link-mobile "
                onClick={() =>
                  setShow(false) & setMove(false) & setScale(false)
                }
              >
                Projects
              </Link>

              <Link
                to="#about"
                className="nav-link nav-link-mobile "
                onClick={() =>
                  setShow(false) & setMove(false) & setScale(false)
                }
              >
                About
              </Link>
              <button
                style={{ textAlign: "left", color:"#3997F7", fontWeight:600 }}
                className="nav-link nav-link-mobile "
                onClick={() =>
                  setShow(false) &
                  setMove(false) &
                  setScale(false) &
                  setActiveForm(true)
                }
              >
                Contact
              </button>
            </motion.div>
          )}
        </AnimatePresence>
      </nav>
      {activeForm && <ContactForm onClose={setActiveForm} />}
    </>
  )
}

export default Navbar


