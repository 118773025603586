import React from "react"
import styled from "styled-components"
import { useState } from "react"
import { ContactForm } from "./ContactForm"

const ConclusionAction = () => {
  const [activeForm, setActiveForm] = useState(false)
  return (
    <Wrapper>
      <div className="skills-wrapper">
        <h1 className="final-action-title text-fa">Let's Talk About Your Project</h1>
        <div className="load-more-btn">
          <button
            className="call-to-action"
            onClick={() => setActiveForm(true)}
          >
            CONTACT ME
          </button>
        </div>
      </div>
      {activeForm ? <ContactForm onClose={setActiveForm} /> : null}
    </Wrapper>
  )
}

export default ConclusionAction

const Wrapper = styled.section`
  background-color: #1e1e21;
`
