import React from "react"
import { Helmet } from "react-helmet"

const SEO = ({ title, description }) => {
  return (
    <Helmet
      title={title}
      meta={[{ name: `description`, content: description }]}
    //   meta={[{ name: `description`, content: description }, {name:`viewport`, content: "width=device-width, minimum-scale=.25, user-scalable=yes"}]}
    ></Helmet>
  )
}

export default SEO
