import React from "react"

import { graphql, useStaticQuery } from "gatsby"
import ProjectCards from "./ProjectCards"

export const query = graphql`
  query {
    allContentfulProjects(sort: {fields: idP, order: ASC}) {
      nodes {
        id
        title
        liveWebsite
        gitHub
        heroImage {
          gatsbyImageData(placeholder: BLURRED, layout: CONSTRAINED)
        }
        video {
          url
        }
        videoMobile {
          url
        }
        overview {
          overview
          goal
          tools
        }
        steps {
          id
          description
          file {
            url
          }
          title
          gatsbyImageData(placeholder: BLURRED, layout: CONSTRAINED)
        }
        finalResult {
          gatsbyImageData(placeholder: BLURRED, layout: CONSTRAINED)
        }
      }
     
    }
  }
`
const AllProjects = () => {
  const data = useStaticQuery(query)
  const projects = data.allContentfulProjects.nodes

// console.log(projects);

  return (
    <section className="skills-wrapper">
      
        <ProjectCards projects={projects}/>
      
    </section>
  )
}

export default AllProjects
