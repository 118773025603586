import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { useState } from "react"
import { ContactForm } from "./ContactForm"

const Hero = () => {
  const [activeForm, setActiveForm] = useState(false)

  return (
    <header className="home-hero width">
      <section className="home-hero-item1">
        <h1 className="hero-h">UX/UI Designer with Developer Skills</h1>
        <section className="hero-paragraphs">
          <p className="hero-p">
            Using modern design techniques to create great user experiences.
            Always ready to adapt to create the best front-end
            solution for your company.
          </p>

          <p className="hero-p"></p>
          <p className="hero-p-mobile">
            Using modern techniques and technologies to
            create the best web solution for your company
          </p>
        </section>

        <div >
          <button
            className="call-to-action"
            onClick={() => setActiveForm(true)}
          >
            CONTACT ME
          </button>
        </div>
      </section>
      <section className="home-hero-item2">
        <StaticImage
          src="../assets/images/ProfileRounded.png"
          alt="Alex.Ms.Design"
          className="hero-img"
          placeholder="blurred"
          layout="fullWidth"
        ></StaticImage>
        
      </section>
      {activeForm ? <ContactForm onClose={setActiveForm} /> : null}
    </header>
  )
}

export default Hero
